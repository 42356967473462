import { Injectable } from '@angular/core';
import { IUser } from '@app/interface/index';
import { map, Observable } from 'rxjs';
import { environment } from '@environments/environment'
import { ApiV5Service } from '@app/service/api';
import { StorageService, ToolService } from '@app/service/utils';
import { ApiV5AppService } from '../api/apiv5.app.service';

const API_AC: string = environment.API_AC;

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(
        private apiV5Service: ApiV5Service,
        private toolService: ToolService,
        private apiV5AppService: ApiV5AppService
    ) {}

    getUserInfo(idUsuario: any){
        const url: string = `${API_AC}/api/users/v5/user/${idUsuario}`;
        return this.apiV5Service.get(url).pipe(
            map((usuariov5: IUser) => usuariov5)
        );
    }

    updateUser(user: IUser, userId: any) {
        const url: string = `${API_AC}/api/users/v5/user/${userId}`;
        return this.apiV5Service.put(url, user)
            .pipe(
                map((userV5: IUser) => userV5)
            );
    }

    changePassword(body: any) {
        const url: string = `${API_AC}/api/users/v5/user/auth/changepassword`;
        return this.apiV5Service.put(url, body);
    }

    getDeleteUserEmail(){
        const url: string = `${API_AC}/api/users/v5/user/deleteAccount`;
        return this.apiV5Service.get(url);
    }

    deleteUser(id: string, hash: string): Observable<any>{
        const url: string =  `${this.toolService.getApiDomain()}/api/users/v5/user/deleteAccount/${id}`
        const params = { hash: hash };
        return this.apiV5AppService.delete(url, "", params);
    }
}